/* CSS */
import "@css/app.pcss";

/* JS */
import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

import { GridStack } from 'gridstack';
window.GridStack = GridStack;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import Clipboard from '@ryangjchandler/alpine-clipboard';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import focus from '@alpinejs/focus';
import iframeResize from 'iframe-resizer/js/iframeResizer';

Alpine.plugin(Clipboard);
Alpine.plugin(Tooltip);
Alpine.plugin(focus);

window.Alpine = Alpine;
Alpine.start();